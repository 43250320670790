import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  handleGetCartInfoStorageItems,
} from "../utilities/cartManager";
import { getUserdata } from "../utilities/sessionexpiry";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { LazyLoadImage }
  from 'react-lazy-load-image-component';
import { AK } from "../constants/AppKeys";
import { AxiosError } from "../utilities/sessionexpiry";
import { AxiosPost } from "../utilities/axioscall";
import '../Components/Homepage.css'

import gifticons from "../Images/gifticon.png";
export default function NavbarMain(props) {
  const { storeid, count, wishlist, setwishlist, setCount, categoryListData, productsListData } = props;
  const [cartinfoData, setCartInfoData] = useState({
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  });
  const [categoryproducts, setCategorypageproducts] = useState([]);
  const [userData, setUserData] = useState(getUserdata());
  const [pageinit, setPageInit] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    console.log("**NavbarMain**");
    handleGetCartInfoStorageItems(setCartInfoData);
    setUserData(getUserdata());
    if (count) {
      console.log("***");
    } else {
      console.log("***");
    }
  }, [count]);





  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const handleItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };
  const onClick_filterCategory = (categoryId, checked) => {
    if (categoryId === 1 && checked) {
      setAvailabeData({
        colorcodes: [{ id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
        size: [{ id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }],
        products: productsListData.slice(0, 100),
      });
      setProductsListDisplayData(productsListData.slice(0, 100));
    } else {
      let prod = productsListData.filter((product) => {
        let catlistfilter = categoryListData.filter((catlist) => {
          if (product?.cat_id === undefined || product?.cat_id === null)
            return false;
          return (
            catlist.checked === true &&
            product.cat_id.toString() === catlist.id.toString().padStart(3, "0")
          );
        });

        return catlistfilter.length > 0;
      });
      setProductsListDisplayData(prod.slice(0, 100));
      setAvailabeData({
        colorcodes: [{ id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
        size: [{ id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }],
        products: prod,
      });
      if (prod.length === 0)
        setAvailabeData({
          colorcodes: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
          size: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
          products: [
            { id: 110 },
            { id: 111 },
            { id: 112 },
            { id: 113 },
            { id: 114 },
          ],
        });
    }
  };


  // const navigate = useNavigate();
  useEffect(() => {
    console.log("***");
  }, [count]);
  const categoryhomepageproducts = useCallback(async () => {


    await AxiosPost(AK.CATEGORY_HOMEPAGE_PRODUCTSAPI, { storeid: storeid }, false)
      .then(async (res) => {

        // console.log(res);
        if (res != typeof undefined && res.data != typeof undefined) {
          // console.log(res.data);

          //console.log(res);
          //console.log("data =="+res.data[0]);
          setCategorypageproducts(res.data);
          // console.log("data =="+res.data[1].products[0].imageurl);
        }
      })
      .catch(async (error) => {
        let errors = AxiosError(error);
        console.log(errors);

      });
  });

  const [isEnabled, setIsEnabled] = useState(true);

  const initializeWhatsappChatWidget = () => {
    if (!isEnabled) return;

    const isOnCheckoutPage = window.location.pathname === '/checkout';
    setIsEnabled(!isOnCheckoutPage); 
    const isOnCartPage = window.location.pathname === '/cart';
    setIsEnabled(!isOnCartPage); 

    if (isOnCheckoutPage) return;
    if (isOnCartPage) return;


    const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?12205';
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    const options = {
      "enabled": isEnabled,
      "chatButtonSetting": {
        "backgroundColor": "#00e785",
        // "ctaText": "Chat with us",
        "borderRadius": "25",
        "marginLeft": "0",
        "marginRight": "20",
        "marginBottom": "20",
        "ctaIconWATI": false,
        "position": "right"
      },
      "brandSetting": {
        "brandName": "Thaithingal",
        "brandSubTitle": "Thaithingal",
        "brandImg": "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/4/favicon.png",
        "welcomeText": "Hi there!\nHow can I help you?",
        "messageText": "Hello. We would want to avail your services. Kindly get in touch with Thaithingal",
        "backgroundColor": "#00e785",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "autoShow": false,
        "phoneNumber": "9176489459 "
      }
    };
    script.onload = () => {
      window.CreateWhatsappChatWidget(options);
    };
    document.body.appendChild(script);
  };



  // useEffect(() => {
  //   // Initialize the WhatsApp chat widget if it's enabled
  // }, [isEnabled]);



  const getcartCount = () => {
    return cartinfoData.cartcount;
  };
  const cartCount = cartinfoData.cartcount

  // console.log(categoryListData)



  useEffect(() => {
    console.log("**NavbarMain**");
    handleGetCartInfoStorageItems(setCartInfoData);
    setUserData(getUserdata());
    if (count) {
      console.log("***");
    } else {
      console.log("***");
    }
  }, [count]);




  if (!pageinit) {
    setPageInit(true);
    // categoryhomepageproducts();
    setUserData(getUserdata());
    initializeWhatsappChatWidget();

  }

  // useEffect(() => {
  //   // Initialize the WhatsApp chat widget if it's enabled
  // }, [isEnabled]);



  // console.log(categoryListData)

  const mainCategories = categoryListData?.filter(category => category.parent_id === 0);

  // Then, sort the main categories based on their IDs
  mainCategories?.sort((a, b) => a.id - b.id);

  const [visibleSubcategories, setVisibleSubcategories] = useState([]);

  // Function to toggle visibility of subcategories
  const toggleSubcategories = (mainCategoryId) => {
    if (visibleSubcategories.includes(mainCategoryId)) {
      setVisibleSubcategories(visibleSubcategories.filter(id => id !== mainCategoryId));
    } else {
      setVisibleSubcategories([...visibleSubcategories, mainCategoryId]);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');
  const navigate2 = useNavigate();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate2(`/products?search=${encodeURIComponent(searchQuery)}`);
  };




  // console.log(searchQuery)
  return (
    <React.Fragment>

      <>
        <Navbar expand="lg" className="navbarmain" >
          <Container fluid>
            <Navbar.Toggle aria-expanded="false"
              aria-controls="navbarScroll"
              className="me-auto"
              style={{ border: "none" }}
            >
              <LazyLoadImage src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/thaithingal/HomepageImages/Vector.png" alt="Toggle Icon" />
            </Navbar.Toggle>
            <Navbar.Brand href="#" className="mx-auto mx-md-0" >
              <a href="/">
                <LazyLoadImage width={'220px'}  className="img  py-0 py-md-2 imglogo img-fluid me-5  me-md-0" src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/4/logo.webp" alt="Thaithingal Logo" />
              </a>

            </Navbar.Brand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="start"
              style={{ width: '80%' }}
            >
              <Offcanvas.Header closeButton  >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand`} >
                  <a href="/">
                    <LazyLoadImage className="img mx-auto" width={'200px'} src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/4/logo.webp" alt="Thaithingal Logo" />
                  </a>
                  {/* mobile view Sign logo start */}
                 
                  {/* mobile view Sign logo End */}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mblnavmain">
                <Link href="#" className="d-flex items-center justify-content-end d-md-none">
                  {" "}
                  <div className=" d-flex items-center border-l font-heading hover:text-gray-600">
                    <Link to={!wishlist ? "/mywishlist" : "/products/traditional-rices"}
                      onClick={() => {
                        setwishlist(!wishlist); setCount({
                          ...count,
                          count: count + 1,
                        });
                      }} >
                        <svg
                          width={23}
                          height={20}
                          viewBox="0 0 23 20"
                          fill={wishlist ? "#f45440" : "none"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                            stroke="green" // Change the stroke color to green
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                      </Link>
                      <Link style={{ position: 'relative' ,paddingLeft:"20px"}}
                        to="/cart"

                      >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z" fill="#629A28" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div>
                          {cartCount === 0 ? (
                            <span className="cartCount2" style={{ display: 'none' }}>{cartCount}</span>
                          ) : (
                            <span className="cartCount2">{cartCount}</span>
                          )}
                        </div>
                      </Link>
                      <Link
                        to="/signin"
                        style={{
                          display: userData != null ? "none" : "flex", paddingLeft:"20px"
                        }}
                      >
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z" stroke="#629A28" stroke-width="1.5" />
                          <path d="M19.998 18C20 17.836 20 17.669 20 17.5C20 15.015 16.418 13 12 13C7.582 13 4 15.015 4 17.5C4 19.985 4 22 12 22C14.231 22 15.84 21.843 17 21.563" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        <span style={{ fontSize: '14px',fontWeight:"700",paddingTop:"4px", paddingRight: '10px',color:'#9b9b9b' }}>
                          SIGN&nbsp;IN
                        </span>
                      </Link>


                    </div>

                  </Link>
                <Form className="d-flex " style={{ width: '100%' }}
                  onSubmit={handleSearchSubmit}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search Products..."
                    className="Header-input"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <Button variant="outline-secondary"
                    className="Header-btn border-0"
                    type="submit"
                  >
                    <LazyLoadImage src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/thaithingal/HomepageImages/Vector%20(1).png" alt="Search Icon" />
                  </Button>
                </Form>
                <Nav
                  className=" ms-auto  my-lg-0 "
                  style={{ maxHeight: "100px" }}

                >
                  <Nav.Link className="ms-4 ms-md-0  d-none d-md-block">
                    {" "}
                    <div className="  items-center  border-l font-heading hover:text-gray-600">

                      <Link className=" hover:text-gray-600" to={!wishlist ? "/mywishlist" : "/products/traditional-rices"} onClick={() => {
                        setwishlist(!wishlist); setCount({
                          ...count,
                          count: count + 1,
                        });
                      }}>
                        <svg
                          width={23}
                          height={20}
                          viewBox="0 0 23 20"
                          fill={wishlist ? "#f45440" : "none"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                      </Link>
                    </div>



                  </Nav.Link>


                  <Nav.Link href="#" className="ms-4 ms-md-0  d-none d-md-block">
                    {" "}
                    <div className="  items-center  border-l font-heading hover:text-gray-600">

                      <Link
                        className="dropdown"
                        to="/cart"
                        style={{
                          display: userData === true ? "none" : "flex",
                          cursor: "pointer;",
                        }}
                      >

                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.5 22.2592C19.8978 22.2592 20.2794 22.1006 20.5607 21.8183C20.842 21.5359 21 21.153 21 20.7537C21 20.3544 20.842 19.9715 20.5607 19.6891C20.2794 19.4068 19.8978 19.2481 19.5 19.2481C19.1022 19.2481 18.7206 19.4068 18.4393 19.6891C18.158 19.9715 18 20.3544 18 20.7537C18 21.153 18.158 21.5359 18.4393 21.8183C18.7206 22.1006 19.1022 22.2592 19.5 22.2592ZM9.5 22.2592C9.89782 22.2592 10.2794 22.1006 10.5607 21.8183C10.842 21.5359 11 21.153 11 20.7537C11 20.3544 10.842 19.9715 10.5607 19.6891C10.2794 19.4068 9.89782 19.2481 9.5 19.2481C9.10218 19.2481 8.72064 19.4068 8.43934 19.6891C8.15804 19.9715 8 20.3544 8 20.7537C8 21.153 8.15804 21.5359 8.43934 21.8183C8.72064 22.1006 9.10218 22.2592 9.5 22.2592Z" fill="#629A28" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5 4.19259H22L20 15.2333M5 4.19259L7 15.2333H20M5 4.19259C4.833 3.52312 4 2.18518 2 2.18518M20 15.2333H5.23C3.446 15.2333 2.5 16.0172 2.5 17.2407C2.5 18.4642 3.446 19.2481 5.23 19.2481H19.5" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>



                        {cartCount === 0 ? (
                          <span className="cartCount" style={{ display: 'none' }}>{cartCount}</span>
                        ) : (
                          <span className="cartCount">{cartCount}</span>
                        )}

                      </Link>
                    </div>



                  </Nav.Link>
                  <Nav.Link href="#action2" className="ms-4 ms-md-0  d-none d-md-block ">
                    {" "}
                    <div className="  items-center  border-l font-heading hover:text-gray-600 ">

                      <Link className="me-3"
                        to="/signin"
                        style={{
                          display: userData != null ? "none" : "flex",
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z" stroke="#629A28" stroke-width="1.5" />
                          <path d="M19.998 18C20 17.836 20 17.669 20 17.5C20 15.015 16.418 13 12 13C7.582 13 4 15.015 4 17.5C4 19.985 4 22 12 22C14.231 22 15.84 21.843 17 21.563" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" />
                        </svg>

                        <span style={{ fontSize: '14px', paddingTop: '5px', paddingLeft: '5px' }}>
                          SIGN&nbsp;IN
                        </span>
                      </Link>

                      <a
                        className="dropdown"
                        href="#"
                        style={{
                          display: userData === null ? "none" : "flex",
                          cursor: "pointer;",
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10Z" stroke="#629A28" stroke-width="1.5" />
                          <path d="M19.998 18C20 17.836 20 17.669 20 17.5C20 15.015 16.418 13 12 13C7.582 13 4 15.015 4 17.5C4 19.985 4 22 12 22C14.231 22 15.84 21.843 17 21.563" stroke="#629A28" stroke-width="1.5" stroke-linecap="round" />
                        </svg>
                        &nbsp;

                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            width: '140px',
                            paddingRight: '15px',
                            paddingTop: "2px",
                            cursor: "pointer;",
                            fontWeight: 900,
                            textTransform: "uppercase",
                            color: '#629A28',
                            fontFamily: 'Lato',
                          }}
                        >
                          {userData?.name}
                        </span>
                        <div
                          className="dropdown-content"
                          style={{ cursor: "pointer;" }}
                        >
                          <Link
                            to="/myaccount"
                            className={
                              activeMenuItem === "myaccount" ? "active" : ""
                            }
                            onClick={() => handleItemClick("myaccount")}
                          >
                            My Account
                          </Link>

                          <Link
                            to="/orderhistory"
                            className={
                              activeMenuItem === "orderhistory" ? "active" : ""
                            }
                            onClick={() => handleItemClick("orderhistory")}
                          >
                            My Orders
                          </Link>

                          <Link
                            to="/mywallet"
                            className={
                              activeMenuItem === "mywallet"
                                ? "active"
                                : ""
                            }
                            onClick={() => handleItemClick("mywallet")}
                          >
                            My Wallet
                          </Link>
                          <hr
                            style={{
                              border: "1px solid #CFD5E2",
                              height: "2px",
                            }}
                          />
                          <Link to="/signin">Logout</Link>
                        </div>
                      </a>
                    </div>



                  </Nav.Link>
                  <hr />
                  <div className="d-flex mblnavbar mt-0 pt-0 d-md-none">
                    <div>

                      <h5 className="mt-0 pt-0 mb-3 mblmenuhead pb-2">SHOP IN</h5>
                      {mainCategories?.map(mainCategory => (
                        <div key={mainCategory.id}>
                          <a className="ms-3 mobilemenu d-flex d-md-none" href="#" onClick={() => toggleSubcategories(mainCategory.id)}>
                            <b>{mainCategory.name}</b>
                          </a>
                          {visibleSubcategories.includes(mainCategory.id) &&
                            categoryListData
                              .filter(subCategory => subCategory.parent_id === mainCategory.id)
                              .map(subCategory => (
                                <div key={subCategory.id}>

                                  <a className="ms-3 submobilemenu uppercase d-flex d-md-none" href={`/products/${subCategory.seo_url}`}>
                                    {subCategory.name}
                                  </a>
                                </div>
                              ))}
                        </div>
                      ))}
                    </div>

                  </div>

                  <h5 className="d-md-none mblmenuhead mb-3 pt-3 pb-1" style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",

                  }}>MY PROFILE</h5>
                  <Link to="/myaccount"className="ms-3 mobilemenu d-flex d-md-none pb-2 "style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",

                    }}>
                      <b style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}>My Account</b>
                  </Link>

                  <Link to="/orderhistory" className="ms-3 mobilemenu d-flex d-md-none pb-2" style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}>
                    <b style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}> My Orders</b>
                  </Link>

                  <Link to="/mywallet" className="ms-3 mobilemenu d-flex d-md-none pb-2" style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}>
                    <b style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}> My Wallet</b>
                  </Link>

                  <Link to="myprofile" className="ms-3 mobilemenu d-flex d-md-none " style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",

                    }}>
                      <b style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}> My Profile</b>
                  </Link>


                  <h5 className="d-flex d-md-none mblmenuhead my-3 pb-2" >CONTACT US</h5>
                  <Link to="/contactus"  className="ms-3 pb-2 mobilemenu d-flex d-md-none ">
                      <b> Help & Support</b>
                    </Link>
                  <Link to="/contactus"  className="ms-3 mobilemenu pb-2 d-flex d-md-none ">
                      <b> Feedback & Suggestions</b>
                  </Link>

                  <Link to="/contactus"  className="ms-3 mobilemenu pb-2 d-flex d-md-none ">
                      <b>  Request a Cell</b>
                  </Link>

                  <h5 className="d-flex d-md-none mblmenuhead my-3" >ABOUT US</h5>
                  <Link to="/storelocation" className="ms-3 pb-2 mobilemenu d-flex d-md-none ">
                      <b>Our STORE</b>
                  </Link>
                  <Link href="#action2" className="ms-3 mobilemenu pb-2 d-flex d-md-none ">
                    <Link to="/products" className=' '>
                      <b>Blog</b>
                    </Link>
                  </Link>
                  <hr style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                    color: 'white'
                  }} />


                  <a href="/signin" className="mobilemenu d-md-none pb-3" style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                    color: 'white',
                  }}>
                    <b>Log out</b>
                  </a>

                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

            <Form className="d-flex d-md-none my-3 inputshow" onSubmit={handleSearchSubmit}>
              <Form.Control
                type="search"
                placeholder="Search Products.."
                className=" Header-input2 m-0 "
                aria-label="Search"
                onChange={handleSearchChange}
              />
              <Button className="Header-btn2 " variant="outline-secondary" onClick={handleSearchSubmit}>
                <LazyLoadImage src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/thaithingal/HomepageImages/Vector%20(1).png" alt="Search ICOn" />
              </Button>
            </Form>
          </Container>
        </Navbar>


 {isEnabled ?  <a href="/survey" target="_blank"> <div className="getexcitingnews">
{/* <img width={"40px"} src={gifticons}></img> */}
 <span>Get Exciting
 <br/> ✨Gifts</span>
    </div></a>: <></>}
      </>
    </React.Fragment>
  );
}
